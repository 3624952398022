<template>
  <div
    class="sidenav mobile-hidden fixed flex flex-wrap flex-column justify-start"
    :class="{ colapse: colapse }"
    :style="{ background: backColor }"
  >
    <div
      id="logoFull"
      style="padding-left: 25px; padding-top: 30px; padding-bottom: 40px"
      class="cursor-pointer"
      @click="$router.push({ name: 'homepage' })"
    >
      <img src="../img/logoTeros.png" height="25px" />
    </div>
    <div
      id="logoMini"
      style="padding-left: 28px; padding-top: 30px; padding-bottom: 40px"
      class="cursor-pointer"
      @click="$router.push({ name: 'homepage' })"
    >
      <img src="../img/TColapsed.png" height="25px" />
    </div>

    <div id="menu">
      <nested-menu
        :backgroundColor="backColor"
        :collapsed="colapse"
        :items="items"
        :is-client="isClient"
      ></nested-menu>
      <div style="position: absolute; bottom: 20px">
        <div id="colapse" class="px-6 mt-3" @click="$emit('colapse')">
          <img src="../img/icon.w.colapse.png" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import { mapGetters } from "vuex";

import NestedMenu from "@/components/nested-menu.vue";
import { CLIENTE, GALAPOS, SAAS } from "@/core/constants/menus";
import { UserTypeEnum } from "@/core/enums/user-types";
import routes from "@/routes";

export default {
  name: "Sidebar",
  props: ["colapse", "me"],
  components: {
    NestedMenu,
  },
  computed: {
    ...mapGetters(["user"]),
    isClient: function () {
      return [
        UserTypeEnum.GERENCIAL_CLIENTE,
        UserTypeEnum.COLABORADOR,
      ].includes(this.user.tipo_usuario);
    },
    items: function () {
      if (!this.user.tipo_usuario || !this.user.empresa) {
        return [];
      }

      if ([UserTypeEnum.ADMINISTRADOR, UserTypeEnum.CONSULTOR].includes(this.user.tipo_usuario)) {
        return this.parseMenu(GALAPOS);
      }

      if ([UserTypeEnum.CLIENTE_ADMINISTRADOR, UserTypeEnum.CLIENTE_CONSULTOR].includes(this.user.tipo_usuario)) {
        return this.parseMenu(SAAS);
      }

      return this.parseMenu(CLIENTE);
    },
  },
  methods: {
    parseMenu: function (items = []) {
      const named = routes.filter(({ name }) => name);

      const authorizedReducer = (acc, item) => {
        const clonedItem = cloneDeep(item);
        const children =
          Array.isArray(item.children) && item.children.length > 0
            ? item.children.reduce(authorizedReducer, [])
            : [];

        if (children.length > 0) {
          clonedItem.children = children;

          if (!clonedItem.to) {
            clonedItem.to = children[0].to;
          }
        }

        if (this.user.tipo_usuario !== UserTypeEnum.ADMINISTRADOR) {
          if (typeof clonedItem.to !== "string") {
            return acc;
          }

          const found = named.find(({ name }) => name === clonedItem.to);

          if (!found) {
            return acc;
          }

          if (found.meta.userTypes) {
            const userTypes = Array.isArray(found.meta.userTypes)
              ? found.meta.userTypes
              : [found.meta.userTypes];

            if (!userTypes.includes(this.user.tipo_usuario)) {
              return acc;
            }
          }

          if (
            found.meta.permissions &&
            !this.userHasAccess(found.meta.permissions)
          ) {
            return acc;
          }
        }

        return [...acc, clonedItem];
      };

      return items.reduce(authorizedReducer, []);
    },
  },
};
</script>

<style scoped lang="scss">
#colapse {
  -webkit-transition: left 0.3s ease-in-out;
  transition: left 0.3s ease-in-out;
}

.colapse #livechat {
  display: none;
}

#colapse img {
  cursor: pointer;
  -webkit-transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
}

.colapse #colapse img {
  transform: rotateZ(-180deg);
}

#logoMini {
  display: none;
}

.colapse #logoMini {
  display: block;
}

.colapse #logoFull {
  display: none;
}

#profile #photo {
  width: 44px;
  height: 44px;
  background: silver;
  border-radius: 50%;
  float: left;
  overflow: hidden;
}

#profile #name {
  margin-left: 60px;
  line-height: 44px;
  font-size: 16px;
}

#profile {
  padding: 15px;
  width: 225px;
  overflow: hidden;
}

#profile:after {
  clear: both;
  content: " ";
}

#menu {
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(223, 224, 235, 0.08);
}

.sidenav {
  color: #abbcd5;
  z-index: 10;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.35);
  position: fixed;
  left: 0;
  top: 0;

  &::-webkit-scrollbar {
    width: 0px;
  }
}

#menu,
.sidenav {
  height: 100vh;
  width: 255px;
  -webkit-transition: width 0.3s ease-in-out, margin-left 0.3s ease-in-out;
  transition: width 0.3s ease-in-out, margin-left 0.3s ease-in-out;
}

.colapse #menu,
.colapse {
  width: 74px !important;
}
</style>
